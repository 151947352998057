#navbar {
  min-height: 5vh;
}

.link-menu:hover {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.link-menu {
  transition: all 600ms;
}

.navbar-toggler {
  background-color: rgba(0, 0, 0, 0.177);
}

.button {
  background-color: red;
}

.button:hover {
  background-color: rgb(232, 20, 20);
}
